import { nanoid } from 'nanoid';
import { StoreApi } from 'zustand';
import { produce } from 'immer';
import { isNil } from 'lodash';
import { ManualValues, OpsCostReportManualAmountsStore } from './interfacesOpsCostReportManualAmounts';


/* not declared with the rest of interfaces because these are just helper types to avoid long lines */
type Actions = OpsCostReportManualAmountsStore['actions'];
type Set = StoreApi<OpsCostReportManualAmountsStore>['setState'];


const getInitialManualValuesForContract = (): ManualValues => ({
  individualTenantCosts: {},
  otherOrTransferableCosts: {},
  yourPrepayments: {},
  individualTenantCostsKeys: [],
  otherOrTransferableCostsKeys: [],
  yourPrepaymentsKeys: [],
});


export const initializeStoreWithExistingValues = (set: Set, ...args: Parameters<Actions['initializeStoreWithExistingValues']>) => set((state) => {
  const [values] = args;

  return produce(state, (draft) => {
    draft.values = values;
  });
});

export const clearStore = (set: Set) => set(state => (
  produce(state, (draft) => {
    draft.values = {};
  })
));


export const addRow = (set: Set, ...args: Parameters<Actions['addRow']>) => set((state) => {
  const [contractId, targetSection] = args;
  const newKey = nanoid();

  return produce(state, (draft) => {
    if (isNil(draft.values[contractId])) {
      draft.values[contractId] = getInitialManualValuesForContract();
    }

    draft.values[contractId][targetSection][newKey] = { sign: 1 };
    draft.values[contractId][`${targetSection}Keys`].push(newKey);
  });
});


export const removeRow = (set: Set, ...args: Parameters<Actions['removeRow']>) => set((state) => {
  const [contractId, targetSection, key] = args;

  return produce(state, (draft) => {
    delete draft.values[contractId][targetSection][key];
    draft.values[contractId][`${targetSection}Keys`] = draft.values[contractId][`${targetSection}Keys`].filter(k => k !== key);
  });
});


export const onChange = (set: Set, ...args: Parameters<Actions['onChange']>) => set((state) => {
  const [contractId, targetSection, key, property, value] = args;

  return produce(state, (draft) => {
    draft.values[contractId][targetSection][key][property] = value;
  });
});
