import { useContext } from 'react';
import { useStore } from 'zustand';
import { OpsCostReportManualAmountsContext } from './storeOpsCostReportManualAmounts';
import {
  IndividualTenantCostManualValue, OpsCostReportManualAmountsStore, OtherOrTransferableCostManualValue, YourPrepaymentManualValue,
} from './interfacesOpsCostReportManualAmounts';

// ! do not export !
const useManualAmountsStore = <T, >(selector: (state: OpsCostReportManualAmountsStore) => T): T => {
  const store = useContext(OpsCostReportManualAmountsContext);

  if (!store) {
    throw new Error('Missing OpsCostReportManualAmountsStoreProvider.');
  }

  return useStore(store, selector);
};


/* Input values */
export const useYourPrepaymentsManualValues = (selectedContractId: number, key: string, property: keyof YourPrepaymentManualValue) => useManualAmountsStore(
  state => (state.values[selectedContractId]?.yourPrepayments[key]?.[property]),
);
export const useIndividualTenantCostsManualValues = (selectedContractId: number, key: string, property: keyof IndividualTenantCostManualValue) => useManualAmountsStore(
  state => (state.values[selectedContractId]?.individualTenantCosts[key]?.[property]),
);
export const useOtherOrTransferableCostsManualValues = (selectedContractId: number, key: string, property: keyof OtherOrTransferableCostManualValue) => useManualAmountsStore(
  state => (state.values[selectedContractId]?.otherOrTransferableCosts[key]?.[property]),
);

export const useOpsCostPrintMode = () => useManualAmountsStore(state => state.printMode);


/* Summary values */
export const useYourPrepaymentsManualValuesTotals = (selectedContractId: number) => useManualAmountsStore(state => (
  Object.values(state.values[selectedContractId]?.yourPrepayments ?? []).reduce((acc, v) => ({
    gross: acc.gross + (v.sign * (v.amountGross ?? 0)),
    net: acc.net + (v.sign * (v.amountNet ?? 0)),
    vat: acc.vat + (v.sign * (v.amountVat ?? 0)),
  }), {
    gross: 0,
    net: 0,
    vat: 0,
  })
));

export const useIndividualTenantCostsManualValuesTotals = (selectedContractId: number) => useManualAmountsStore(state => (
  Object.values(state.values[selectedContractId]?.individualTenantCosts ?? []).reduce((acc, v) => ({
    yourAmountGross: acc.yourAmountGross + (v.sign * (v.yourAmountGross ?? 0)),
    yourAmountNet: acc.yourAmountNet + (v.sign * (v.yourAmountNet ?? 0)),
    yourAmountVat: acc.yourAmountVat + (v.sign * (v.yourAmountVat ?? 0)),
    totalAmountNet: acc.totalAmountNet + (v.sign * (v.totalAmountNet ?? 0)),
    totalAmountGross: acc.totalAmountGross + (v.sign * (v.totalAmountGross ?? 0)),
  }), {
    yourAmountGross: 0,
    yourAmountNet: 0,
    yourAmountVat: 0,
    totalAmountNet: 0,
    totalAmountGross: 0,
  })
));

export const useOtherOrTransferableCostsManualValuesTotals = (selectedContractId: number) => useManualAmountsStore(state => (
  Object.values(state.values[selectedContractId]?.otherOrTransferableCosts ?? []).reduce((acc, v) => ({
    yourAmountGross: acc.yourAmountGross + (v.sign * (v.yourAmountGross ?? 0)),
    yourAmountNet: acc.yourAmountNet + (v.sign * (v.yourAmountNet ?? 0)),
    yourAmountVat: acc.yourAmountVat + (v.sign * (v.yourAmountVat ?? 0)),
    totalAmountNet: acc.totalAmountNet + (v.sign * (v.totalAmountNet ?? 0)),
    totalAmountGross: acc.totalAmountGross + (v.sign * (v.totalAmountGross ?? 0)),
  }), {
    yourAmountGross: 0,
    yourAmountNet: 0,
    yourAmountVat: 0,
    totalAmountNet: 0,
    totalAmountGross: 0,
  })
));


/**
 * We also use the selectors manually in the htmlGenerator, that's why they're separated from the hook.
 */
export const selectYourPrepaymentsManualTotalFromStore = (state: OpsCostReportManualAmountsStore['values'], contractId: number) => (
  Object.values(state[contractId]?.yourPrepayments ?? []).reduce((acc, v) => ({
    gross: acc.gross + (v.sign * (v.amountGross ?? 0)),
    net: acc.net + (v.sign * (v.amountNet ?? 0)),
    vat: acc.vat + (v.sign * (v.amountVat ?? 0)),
  }), {
    gross: 0,
    net: 0,
    vat: 0,
  })
);

export const selectOtherOrTransferableCostsManualTotalFromStore = (state: OpsCostReportManualAmountsStore['values'], contractId: number) => (
  Object.values(state[contractId]?.otherOrTransferableCosts ?? []).reduce((acc, v) => ({
    yourAmountGross: acc.yourAmountGross + (v.sign * (v.yourAmountGross ?? 0)),
    yourAmountNet: acc.yourAmountNet + (v.sign * (v.yourAmountNet ?? 0)),
    yourAmountVat: acc.yourAmountVat + (v.sign * (v.yourAmountVat ?? 0)),
    totalAmountNet: acc.totalAmountNet + (v.sign * (v.totalAmountNet ?? 0)),
    totalAmountGross: acc.totalAmountGross + (v.sign * (v.totalAmountGross ?? 0)),
  }), {
    yourAmountGross: 0,
    yourAmountNet: 0,
    yourAmountVat: 0,
    totalAmountNet: 0,
    totalAmountGross: 0,
  })
);

export const selectIndividualTenantCostsManualTotalFromStore = (state: OpsCostReportManualAmountsStore['values'], contractId: number) => (
  Object.values(state[contractId]?.individualTenantCosts ?? []).reduce((acc, v) => ({
    yourAmountGross: acc.yourAmountGross + (v.sign * (v.yourAmountGross ?? 0)),
    yourAmountNet: acc.yourAmountNet + (v.sign * (v.yourAmountNet ?? 0)),
    yourAmountVat: acc.yourAmountVat + (v.sign * (v.yourAmountVat ?? 0)),
    totalAmountNet: acc.totalAmountNet + (v.sign * (v.totalAmountNet ?? 0)),
    totalAmountGross: acc.totalAmountGross + (v.sign * (v.totalAmountGross ?? 0)),
  }), {
    yourAmountGross: 0,
    yourAmountNet: 0,
    yourAmountVat: 0,
    totalAmountNet: 0,
    totalAmountGross: 0,
  })
);


export const useYourPrepaymentsManualTotalFromStore = (contractId: number) => useManualAmountsStore(state => selectYourPrepaymentsManualTotalFromStore(state.values, contractId));
export const useOtherOrTransferableCostsManualTotalFromStore = (contractId: number) => useManualAmountsStore(state => selectOtherOrTransferableCostsManualTotalFromStore(state.values, contractId));
export const useIndividualTenantCostsManualTotalFromStore = (contractId: number) => useManualAmountsStore(state => selectIndividualTenantCostsManualTotalFromStore(state.values, contractId));


export const useOpsCostReportAllManualValues = () => useManualAmountsStore(state => state.values);

export const useOpsCostReportManualAmountsActions = () => useManualAmountsStore(state => state.actions);
