import { Alert, ConfigProvider, Table } from 'antd';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import SubSectionWrapper from 'storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { Link } from 'react-router-dom';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import { opsCostTranslation } from '../../../../../translations';
import ExpenseTableSummary from '../components/ExpenseTableSummary';
import { useHeatingCostSettlementSection as hook } from './useHeatingCostSettlementSection';


type ReturnTypeOfHook = ReturnType<typeof hook>;
export type GetHeatingCostSettlementSectionProps = ReturnTypeOfHook['getHeatingCostSettlementSectionProps'];
type ReturnTypeOfHeatingCostSettlementHook = ReturnType<GetHeatingCostSettlementSectionProps>;


interface HeatingCostSettlementSectionProps {
  sectionNumber: number;
  tl: any;
  useHeatingCostSettlementSection: ReturnTypeOfHeatingCostSettlementHook;
}


const HeatingCostSettlementSection = ({
  sectionNumber,
  tl,
  useHeatingCostSettlementSection,
}: HeatingCostSettlementSectionProps) => {
  const {
    columns,
    wkaRelevantExpenseAccountBalances,
    totalSummaryData,
    loading,
    areWkaValuesCorrect,
    noWkaSelectedIssue,
    getAccountBalancePath,
    wkaLink,
    setAreWkaValuesCorrect,
  } = useHeatingCostSettlementSection;

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(wkaRelevantExpenseAccountBalances) ? { y: 200 } : undefined;

  const wkaRelevantAccountBalancesInfo = (
    <ul>
      {
        wkaRelevantExpenseAccountBalances.map(ab => (
          <li key={ab.accountCode}>
            <Link to={getAccountBalancePath(ab.accountCode)} target="_blank" rel="noopener noreferrer">
              {`${ab.accountCode} - ${ab.accountName}`}
            </Link>
          </li>
        ))
      }
    </ul>

  );

  const incorrectWkaArticleLink = (
    <AlertArticleLink
      content={tl(opsCostTranslation.report.sections.yourSettlementSection.expenseSubSection.alerts.incorrectWkaArticleLinkTitle)}
      url={articleUrls.opsCostReport.incorrectWka}
    />
  );

  const missingWkaArticleLink = (
    <AlertArticleLink
      content={tl(opsCostTranslation.report.sections.yourSettlementSection.expenseSubSection.alerts.missingWkaArticleLinkTitle)}
      url={articleUrls.opsCostReport.missingWka}
    />
  );

  const incorrectWkaAlertDescription = (
    <>
      {wkaRelevantAccountBalancesInfo}
      {incorrectWkaArticleLink}
    </>
  );

  const missingWkaAlertDescription = (
    <>
      {wkaRelevantAccountBalancesInfo}
      {missingWkaArticleLink}
    </>
  );

  const getIncorrectWkaValuesMessage = (
    <>
      {tl(opsCostTranslation.report.sections.yourSettlementSection.expenseSubSection.alerts.wkaValuesAreIncorrectFirst)}
      <Link to={wkaLink.path}>
        {wkaLink.label}
      </Link>
      {' '}
      {tl(opsCostTranslation.report.sections.yourSettlementSection.expenseSubSection.alerts.wkaValuesAreIncorrectSecond)}
    </>
  );

  return (
    <SubSectionWrapper title={`${sectionNumber}.1 ${tl(opsCostTranslation.report.sections.yourSettlementSection.expenseSubSection.title)}`}>
      {!areWkaValuesCorrect
        && (
          <Alert
            message={getIncorrectWkaValuesMessage}
            description={incorrectWkaAlertDescription}
            type="warning"
            showIcon
            closable
            closeIcon={<Icon component={ICONS.closeForAlert} />}
            onClose={() => setAreWkaValuesCorrect(true)}
            icon={<Icon component={ICONS.warning} />}
          />
        )
      }
      {noWkaSelectedIssue
        && (
          <Alert
            message={tl(opsCostTranslation.report.sections.yourSettlementSection.expenseSubSection.alerts.noWkaSelectedIssue)}
            description={missingWkaAlertDescription}
            type="warning"
            showIcon
            icon={<Icon component={ICONS.warning} />}
          />
        )
      }
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.common)} />}>
        <Table
          columns={columns}
          dataSource={wkaRelevantExpenseAccountBalances}
          rowKey="accountCode"
          pagination={false}
          scroll={tableHeight}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <ExpenseTableSummary
              title={tl(opsCostTranslation.report.sections.yourSettlementSection.expenseSubSection.subtotal)}
              totalSummaryData={totalSummaryData}
              nrTotalColumns={columns.length}
              addPlaceholderLastColumn={false}
            />
          )}
        />
      </ConfigProvider>
    </SubSectionWrapper>
  );
};

export default HeatingCostSettlementSection;
